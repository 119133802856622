import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import moment from 'moment';
import { red } from '@mui/material/colors';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { LoadingPageContext } from "../../../contexts/LoadingPageProvider/context";
import { closeLoadingPage, openLoadingPage } from "../../../contexts/LoadingPageProvider/action";
import { userService } from "../../../services/user.service";
import { DialogConfirm } from "../../../components/Dialog/Confirm";
import { TextField } from "@mui/material";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { toastifyNotify } from "../../../helpers/toast-notify";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headerCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headerCells.map((headerCell) => (
                    <TableCell
                        key={headerCell.id}
                        align={headerCell.align}
                        padding={headerCell.disablePadding ? 'none' : 'normal'}
                        sx={{ minWidth: headerCell.minWidth, color: 'black', fontWeight: 'bold' }}
                        sortDirection={orderBy === headerCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headerCell.id}
                            direction={orderBy === headerCell.id ? order : 'asc'}
                            onClick={createSortHandler(headerCell.id)}
                        >
                            {headerCell.label}
                            {orderBy === headerCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected, handleDeleteEntity, handleFilterFileName } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%', paddingTop: 1 }}
                    variant="h2"
                    id="tableTitle"
                    component="div">

                    <TextField
                        autoComplete='off'
                        fullWidth id="filter"
                        label="Filter by name"
                        variant="standard"
                        color="primary"
                        focused
                        size="small"
                        onChange={handleFilterFileName} />
                </Typography>

            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton onClick={() => handleDeleteEntity()}>
                        <DeleteIcon sx={{ color: red[600] }} />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export const SortingSelectionTable = (props) => {
    const { headerCells, allEntities, handleAddEntity, handleEditEntity } = props;
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('fullName');
    const [page, setPage] = useState(0);
    const [selected, setSelected] = useState([]);
    const [indexRow, setIndexRow] = useState(-1);
    const [dialogConfirmed, setDialogConfirmed] = useState(false);
    const [messageDialogConfirm, setMessageDialogConfirm] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
    const [titleDialogConfirm, setTitleDialogConfirm] = useState('');
    const { loadingPageDispatch } = useContext(LoadingPageContext);
    const [entities, setEntities] = useState([]);

    useEffect(() => {
        setEntities(allEntities);
    }, [allEntities])

  

    const handleDeleteEntity = () => {
        // handleUpdate();
        setOpenDialogConfirm(true);
        setTitleDialogConfirm('Delete entity');
        setMessageDialogConfirm(
            selected.length > 1
                ? `Do you want delete  ${selected.length} entities?`
                : `Do you want delete 1 ${selected.map(entity => { return entity.fullName })}`
        );
    };

    const handleRemoveDeleted = useCallback((entitiesDeleted) => {
        if (entitiesDeleted.length > 0) {
            let newSelected = allEntities;
            entitiesDeleted.forEach((u) => {
                newSelected = newSelected.filter(value => value.id !== u.id)
            })

            setEntities(newSelected);
            setSelected([]);
        }
    }, [allEntities])

    //Delete entities selected
    useEffect(() => {

        if (dialogConfirmed) {
            setDialogConfirmed(false);
            openLoadingPage(loadingPageDispatch);

            async function fetchData() {
                await userService.patch(selected)
                    .then(() => {
                        toastifyNotify.success("entities were delete.");
                        handleRemoveDeleted(selected);
                    })
                    .catch(() => { });
                closeLoadingPage(loadingPageDispatch);
            }
            fetchData();
        }
    }, [dialogConfirmed, selected, loadingPageDispatch, handleRemoveDeleted])

    const handleFilterFileName = (e) => {
        props.handleGetAllEntities();
        let filtered = allEntities;
        if (e.target.value.length > 0) {
            filtered = filtered.filter(value => {
                return value.fullName.toUpperCase().includes(e.target.value.toUpperCase())
            });
            setEntities(filtered);
        }
        else {
            setEntities(allEntities);
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {

        if (event.target.checked) {
            const newEntities = entities.map((n) => n);
            setSelected(newEntities);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, entity) => {
        const selectedIndex = selected.indexOf(entity);

        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, entity);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // const handleAddEntity = () => {
    //     navigate('new', { state: { from: location } });
    // };

    // const handleEditEntity = (entity) => {
    //     navigate(`edit/${entity.id}`, { state: { from: location } });
    // };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - entities.length) : 0;

    return (
        <>
            {allEntities ? (
                <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2, overflow: 'hidden' }}>
                        <EnhancedTableToolbar
                            numSelected={selected.length}
                            handleDeleteEntity={handleDeleteEntity}
                            handleFilterFileName={handleFilterFileName} />
                        <TableContainer sx={{ maxHeight: '50vh' }}>
                            <Table
                                sx={{ minWidth: 750 }}
                                stickyHeader
                                aria-label="sticky table"
                                size={'medium'}
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={entities.length}
                                    headerCells={headerCells}
                                />
                                <TableBody>
                                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                                rows.slice().sort(getComparator(order, orderBy)) */}
                                    {stableSort(entities, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((entity, index) => {
                                            const isItemSelected = isSelected(entity);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, entity)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={entity.id}
                                                    selected={isItemSelected}
                                                    onMouseEnter={() => setIndexRow(index)}
                                                    onMouseLeave={() => setIndexRow(-1)}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left">{entity.fullName}</TableCell>
                                                    <TableCell align="left">{entity.email}</TableCell>
                                                    <TableCell align="left">{entity.userRole.description}</TableCell>
                                                    <TableCell align="left">{moment(entity.createDate).format('DD/MM/YYYY HH:MM')}</TableCell>
                                                    <TableCell align="right" sx={{ p: 0, mr: 2 }} >
                                                        {indexRow >= 0 && indexRow === index &&
                                                            <>
                                                                <Tooltip title={'Edit entity'} placement="top"  >
                                                                    <IconButton sx={{ mr: 2.5 }} size={'20ch'} onClick={() => handleEditEntity(entity)}>
                                                                        <ModeEditOutlineIcon color={'primary'} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: 53 * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={entities.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>

                </Box>) : (
                <Box
                    sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Typography display="inline" variant="h7" gutterBottom>
                        No records
                    </Typography>
                </Box>
            )}


            {useMemo(() => {
                return <DialogConfirm
                    open={openDialogConfirm}
                    title={titleDialogConfirm}
                    message={messageDialogConfirm}
                    nameBtnCancel={'Cancel'}
                    nameBtnConfirm={'Delete'}
                    setDialogConfirmed={setDialogConfirmed}
                    setOpenDialogConfirm={setOpenDialogConfirm}
                />
            }, [openDialogConfirm, titleDialogConfirm, messageDialogConfirm, setOpenDialogConfirm, setDialogConfirmed])}

            <Box sx={{ m: 1, display: 'flex', justifyContent: 'right' }}>
                <Fab size="medium" color="success" aria-label="add" onClick={() => handleAddEntity()}>
                    <AddIcon />
                </Fab>
            </Box>
        </>
    );
}
