import { Grid, Link, Stack, Typography } from "@mui/material"
import foguete from "../../../images/foguete.svg"

export const Home = () => {

   
    return (
        <>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                paddingTop={13}
            >

                <Grid item xs={12}>
                    <img width={'400vw'} alt='logo' src={foguete} />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h4" component="div" gutterBottom>
                        Azure DevOps - Laboratory
                    </Typography>
                </Grid>
                
                <Grid item xs={12}>
                    <Typography alignItems="center" variant="caption" display="block" gutterBottom>
                        Created by <Link
                            target="_blank"
                            rel="noreferrer"
                            underline="hover"
                            href="https://www.linkedin.com/in/augusto-oliveira-a1a76611a" >
                            Augusto Oliveira - ({process.env.REACT_APP_ENV_ENVIROMENT})
                        </Link>
                    </Typography>
                </Grid>
            </Stack>

        </>

    )
}