import React from 'react';
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './contexts/AuthProvider';
import { DialogProvider } from './contexts/DialogProvider';
import { LoadingPageProvider } from './contexts/LoadingPageProvider';
import './index.css';

createRoot(document.getElementById('root'))
  .render(
    // <React.StrictMode>
      <BrowserRouter>
        <LoadingPageProvider>
          <DialogProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </DialogProvider>
        </LoadingPageProvider>
      </BrowserRouter>
    // </React.StrictMode>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
