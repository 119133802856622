import axios from 'axios';
import { handleResponse } from "../helpers/handleResponse";
import { buildHeaders } from "../helpers/handleHeader";

const baseURL = process.env.REACT_APP_API_DEVOPS;
const timeout = process.env.REACT_APP_API_DEVOPS_TIMEOUT;

export const userRoleService = {

    getById: async (id) => {
        let headers = await buildHeaders.authHeaderJson();
        return await axios.get(`${baseURL}userrole/getbyid?Id=${id}`, { timeout: timeout, headers: headers })
            .then(result => {
                return handleResponse.success(result);
            })
            .catch((error) => {
                return handleResponse.error(error)
            })
    },
    getAll: async () => {
        let headers = await buildHeaders.authHeaderJson();
        return await axios.get(`${baseURL}userrole/getall`, { timeout: timeout, headers: headers })
            .then(result => {
                return handleResponse.success(result);
            })
            .catch((error) => {
                return handleResponse.error(error)
            })
    }
};