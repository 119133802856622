import { handleLocalStorageCredentials } from "./handleLocalStorageCredentials";

async function authHeaderJson() {

    var credentials = handleLocalStorageCredentials.getCredentials();

    let headers = {
        "Content-Type": "application/json; charset=utf-8",
        'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOWED_ORIGIN,
        "Authorization": `Bearer ${credentials?.token}`
    }
    return headers;
}

export const buildHeaders = {
    authHeaderJson
};