import { Navigate, Outlet, useLocation } from "react-router-dom";
import { handleLocalStorageCredentials } from "./helpers/handleLocalStorageCredentials";

const ProtectedRouter = () => {
    const location = useLocation();

    return handleLocalStorageCredentials.isAuthenticate() ? (<Outlet />
    ) : (
        <Navigate to='/login' replace state={{ from: location }} />
    );
}

export default ProtectedRouter;