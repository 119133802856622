import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PageNotFound } from './pages/PageNotFound';
import ProtectedRouter from './protectedRouter';
import { Login } from './pages/Login';
import { UserCreateAndUpdate } from './pages/Administrator/User/CreateAndUpdate';
import { UserList } from './pages/Administrator/User/List';
import { Home } from './pages/Home/Home';
import { About } from './pages/Home/About';
import { Contact } from './pages/Home/Contact';
import { BillsList } from './pages/Bills/List';
import { BillsCreateAndUpdate } from './pages/Bills/CreateAndUpdate';

const Router = () => {
    return (
        <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/'>
                <Route index element={<Home />} />
                <Route path='about' element={<About />} />
                <Route path='contact' element={<Contact />} />
            </Route>
            <Route element={<ProtectedRouter />}>
                <Route path='users'>
                    <Route index element={<UserList />} />
                    <Route path='new' element={<UserCreateAndUpdate />} />
                    <Route path='edit/:userId' element={<UserCreateAndUpdate />} />
                </Route>
                <Route path='bills'>
                    <Route index element={<BillsList />} />
                    <Route path='new' element={<BillsCreateAndUpdate />} />
                    <Route path='edit/:billId' element={<BillsCreateAndUpdate />} />
                </Route>
            </Route>
            <Route path='*' element={<PageNotFound />} />
        </Routes>
    );
};

export default Router;