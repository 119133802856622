export const headerCells = [
    {
        id: 'fullName',
        label: 'Name',
        align: 'left',
        disablePadding: false,
        minWidth: '200px'
    },
    {
        id: 'email',
        label: 'E-mail',
        align: 'left',
        disablePadding: false,
        minWidth: '20px'
    },
    {
        id: 'role',
        label: 'Role',
        align: 'left',
        disablePadding: false,
        minWidth: '80px'
    },
    {
        id: 'createDate',
        label: 'Create date',
        align: 'left',
        disablePadding: false,
        minWidth: '100px'
    },
    {
        id: 'action',
        label: 'Edit',
        align: 'right',
        disablePadding: false,
        minWidth: '60px'
    }
];