
import { handleLocalStorageCredentials } from '../../helpers/handleLocalStorageCredentials';
import * as types from './types';

export const authenticateUser = async (dispatch, payload) => {

    handleLocalStorageCredentials.saveCredentials(payload);
    dispatch({ type: types.SET_LOGIN, payload: payload });

};

export const userSignOut = async (dispatch) => {
    handleLocalStorageCredentials.removeCredentials();
    dispatch({ type: types.SET_LOGOUT });
};

export const setCredentialToContext = async (dispatch, payload) => {
    dispatch({ type: types.SET_LOGIN, payload });
};