import { useState } from "react";
import { TitleHeader } from "../../../components/Menu/TitleMenu"

export const BillsCreateAndUpdate = () => {

    const [isEditMode, setIsEditMode] = useState(false);

    return (
        <>
            <TitleHeader title={isEditMode ? 'Edit bill' : 'Create bills'} />
        </>
    )
}