import { useContext, useEffect } from 'react';
import { ToastContainer, Zoom } from 'react-toastify';
import { SideNav } from './components/Menu/SideNav';
import { AuthContext } from './contexts/AuthProvider/context';
import { PageLoader } from './components/Loaders/PageLoader';
import { setCredentialToContext } from './contexts/AuthProvider/action';
import { handleLocalStorageCredentials } from './helpers/handleLocalStorageCredentials';
import { LoadingPageContext } from './contexts/LoadingPageProvider/context';
import './App.css';

function App() {

  const { userState, userDispatch } = useContext(AuthContext);
  const { loadingPageState } = useContext(LoadingPageContext);
  const { isAuthenticate } = userState;

  useEffect(() => {
    if (handleLocalStorageCredentials.isAuthenticate() && !isAuthenticate) {
      setCredentialToContext(userDispatch, handleLocalStorageCredentials.getCredentials());
    }
  }, [userDispatch, isAuthenticate]);

  return (
    <>
      <SideNav />
      <PageLoader loading={loadingPageState.open} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={3}
        transition={Zoom}
      />
    </>
  );
}

export default App;