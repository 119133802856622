import axios from 'axios';
import { handleResponse } from "../helpers/handleResponse";
import { buildHeaders } from "../helpers/handleHeader";

const baseURL = process.env.REACT_APP_API_DEVOPS;
const timeout = process.env.REACT_APP_API_DEVOPS_TIMEOUT;

export const userService = {

    getById: async (id) => {
        let headers = await buildHeaders.authHeaderJson();
        return await axios.get(`${baseURL}user/getbyid?Id=${id}`, { timeout: timeout, headers: headers })
            .then(result => {
                return handleResponse.success(result);
            })
            .catch((error) => {
                return handleResponse.error(error)
            })
    },
    getAll: async () => {
        let headers = await buildHeaders.authHeaderJson();
        return await axios.get(`${baseURL}user/getall`, { timeout: timeout, headers: headers })
            .then(result => {
                return handleResponse.success(result);
            })
            .catch((error) => {
                return handleResponse.error(error)
            })
    },
    create: async (body) => {
        let headers = await buildHeaders.authHeaderJson();
        return await axios.post(`${baseURL}user/create`, body, { timeout: timeout, headers: headers })
            .then(result => {
                return handleResponse.success(result);
            })
            .catch((error) => {
                return handleResponse.error(error)
            })
    },
    update: async (body) => {
        let headers = await buildHeaders.authHeaderJson();
        return await axios.put(`${baseURL}user/update`, body, { timeout: timeout, headers: headers })
            .then(result => {
                return handleResponse.success(result);
            })
            .catch((error) => {
                return handleResponse.error(error)
            })
    },
    delete: async (body) => {
        let headers = await buildHeaders.authHeaderJson();
        return await axios.delete(`${baseURL}user/delete`, body, { timeout: timeout, headers: headers })
            .then(result => {
                return handleResponse.success(result);
            })
            .catch((error) => {
                return handleResponse.error(error)
            })
    },
    patch: async (body) => {
        let headers = await buildHeaders.authHeaderJson();
        return await axios.patch(`${baseURL}user/delete`, body, { timeout: timeout, headers: headers })
            .then(result => {
                return handleResponse.success(result);
            })
            .catch((error) => {
                return handleResponse.error(error)
            })
    }

};