import React, { useContext, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import Router from '../../../router';
import { AuthContext } from '../../../contexts/AuthProvider/context';
import GroupIcon from '@mui/icons-material/Group';
import PaymentsIcon from '@mui/icons-material/Payments';
import { Avatar, Badge, Menu, MenuItem, Stack } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import augusto from '../../../images/augusto.jpeg'
import Fade from '@mui/material/Fade';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import { blue } from '@mui/material/colors';
import { userSignOut } from '../../../contexts/AuthProvider/action';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export const SideNav = () => {
  const { userState, userDispatch } = useContext(AuthContext);
  const { user, isAuthenticated } = userState;
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const location = useLocation();
  const navigate = useNavigate();

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: isAuthenticated ? '#44b700' : 'red',
      color: isAuthenticated ? '#44b700' : 'red',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: isAuthenticated ? '' : 'ripple 3s infinite ease-in-out',
        border: '3px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogin = () => {
    setAnchorEl(null);
    navigate('login', { user: { from: location } });
  }

  const handleLogout = () => {
    setAnchorEl(null);
    userSignOut(userDispatch);
  }

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }


  return (
    <Box sx={{ display: 'flex' }} >
      <CssBaseline />
      <AppBar position="fixed" open={open} >
        <Toolbar >

          <Box display='flex' flexGrow={1}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}>

              <MenuIcon />
            </IconButton>

            <Typography variant="h6" noWrap component="div">
              DevOps Laboratory
            </Typography>

          </Box>

          {isAuthenticated ? (
            <Stack direction="row" spacing={2} sx={{ mr: 2 }}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot">

                {user?.firstName.toUpperCase() === 'AUGUSTO' ? (
                  <Avatar alt="Perfil" src={augusto} />
                ) : (
                  <Avatar {...stringAvatar(`${user.firstName} ${user.lastName}`)} />
                )}
              </StyledBadge>
            </Stack>
          ) : (

            <List sx={{ mr: 2 }}>
              <ListItem sx={{ p: 0, mr: 0 }} button component={Link} to="login">
                <LoginIcon />
                <ListItemText primary={'Login'} />
              </ListItem>
            </List>

          )}

          <Badge badgeContent={isAuthenticated ? 3 : 0} color="primary" sx={{ mr: 1, mt: 0 }}>
            <MailIcon fontSize='small' color="white" />
          </Badge>

          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={openMenu ? 'long-menu' : undefined}
            aria-expanded={openMenu ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}>

            <MoreVertIcon fontSize='medium' sx={{ color: blue[50] }} />
          </IconButton>

          <Menu
            id="fade-menu"
            color='red'
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            {isAuthenticated ? (
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem onClick={handleLogin}>
                <ListItemIcon>
                  <LoginIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Login</ListItemText>
              </MenuItem>
            )}
            <Divider />
            <MenuItem onClick={() => navigate('about')}>
              <ListItemIcon>
                <HowToRegIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>About me</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => navigate('contact')}>
              <ListItemIcon>
                <MiscellaneousServicesIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>How it works import</ListItemText>
            </MenuItem>
          </Menu>
        </Toolbar>

      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem component={Link} to="/">
            <ListItemIcon>
              <HomeIcon sx={{ color: blue[600] }} />
            </ListItemIcon>
            <ListItemText primary={'Home'} />
          </ListItem>
          <ListItem component={Link} to="users">
            <ListItemIcon>
              <GroupIcon sx={{ color: blue[600] }} />
            </ListItemIcon>
            <ListItemText primary={'Users'} />
          </ListItem>
          <ListItem component={Link} to="bills">
            <ListItemIcon>
              <PaymentsIcon sx={{ color: blue[600] }} />
            </ListItemIcon>
            <ListItemText primary={'Bills'} />
          </ListItem>
        </List>
        <Divider />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, pl: 3, pr: 3, mt: 9 }} >
        <Router />
      </Box>
    </Box>
  );
}