import { useReducer } from "react";
import { reducer } from "./reducer";
import { DialogContext } from "./context";
import { data } from './data'
import P from 'prop-types';

export const DialogProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, data);

    return (
        <DialogContext.Provider value={{ dialogState: state, dialogDispatch: dispatch }}>
            {children}
        </DialogContext.Provider>
    )
};

DialogContext.prototype = {
    children: P.node.isRequired
}



