import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TitleHeader } from "../../../components/Menu/TitleMenu"
import { SortingSelectionTable } from "../../../components/Table/Sorting&Selection"
import { closeLoadingPage, openLoadingPage } from "../../../contexts/LoadingPageProvider/action";
import { LoadingPageContext } from "../../../contexts/LoadingPageProvider/context";
import { billsService } from "../../../services/bills.service";
import { headerCells } from "./headCells";

export const BillsList = () => {

    const { loadingPageDispatch } = useContext(LoadingPageContext);
    const [allBills, setAllBills] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    // const handleUpdate = () => {
    //     console.log('Atualizado');
    // }

    const handleGetAllBills = useCallback(() => {

            openLoadingPage(loadingPageDispatch);
            async function fetchData() {
                await billsService.getAll()
                    .then(response => {
                        setAllBills(response);
                    })
                    .catch(() => {
                        closeLoadingPage(loadingPageDispatch);
                    })

                closeLoadingPage(loadingPageDispatch);
            }
            fetchData();

    }, [loadingPageDispatch])

    useEffect(() => {
        handleGetAllBills();
    },[handleGetAllBills])

    const handleAddBills = () => {
        navigate('new', { state: { from: location } });
    };

    const handleEditBills = (entity) => {
        navigate(`edit/${entity.id}`, { state: { from: location } });
    };


    // const handleGetAllBills = () => {
    //     console.log('handleGetAllBills');

    //     async function fetchData() {
    //         await userService.getAll()
    //             .then(response => {
    //                 setAllEntities(response);
    //             })
    //             .catch(() => {
    //                 closeLoadingPage(loadingPageDispatch);
    //             })

    //         closeLoadingPage(loadingPageDispatch);
    //     }
    //     fetchData();
    // }

    // const handleDeleteUsers = (usersToBeDeletes) => {
    //     console.log('handleGetAllBills');
    // }

    

    //Get all entities.
    // useEffect(() => {
    //     openLoadingPage(loadingPageDispatch);

    //     async function fetchData() {
    //         await billsService.getAll()
    //             .then(response => {
    //                 setAllBills(response);
    //             })
    //             .catch(() => {
    //                 closeLoadingPage(loadingPageDispatch);
    //             })

    //         closeLoadingPage(loadingPageDispatch);
    //     }
    //     fetchData();
    // }, [loadingPageDispatch])

    return (
        <>
            <TitleHeader title='Bills' />
            <SortingSelectionTable
                headerCells={headerCells}
                allEntities={allBills}
                handleGetAllEntities={handleGetAllBills}
                handleAddEntity={handleAddBills}
                handleEditEntity={handleEditBills}
            />
        </>
    )
}