import {  Chip, Typography } from "@mui/material";
import Divider from '@mui/material/Divider';


export const TitleHeader = (props) => {
    const { title } = props;
    return (

        <Typography sx={{ mb: 3, mt:2 }} variant="button" display="block" gutterBottom>
            <Divider >
                <Chip label={title} />
            </Divider>
        </Typography>

    )
}