import * as types from './types';

export const reducer = (state, action) => {

    switch (action.type) {
        case types.SET_OPEN:
            return {
                ...state, 
                message: action.payload.message,
                tittle: action.payload.tittle,
                open: true,
            }
        case types.SET_CLOSE:
            return {
                ...state,
                message: '',
                tittle: '',
                open: false,
            }
        default:
            return {
                ...state
            }
    }
}