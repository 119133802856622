import { toastifyNotify } from "./toast-notify";

export const handleResponse = {

    success: async (response) => {
        return Promise.resolve(response.data);
    },

    error: async (err) => {
        let error = {
            statusCode: err.response.status,
            message: err.message,
            title: err.response.data?.title ? err.response.data.title : err.response.data
        }

        console.error('handleResponse:', error.title)

        switch (error.statusCode) {
            case 0:
                toastifyNotify.error(<div>{error.message}</div>);
                break;
            case 204:
                toastifyNotify.warning(<div>No Content: {error.statusCode} <br /> {error.title}</div>);
                break;
            case 400:
                toastifyNotify.error(<div>Bad Request: {error.statusCode} <br /> {error.title}</div>);
                break;
            case 401:
                toastifyNotify.error(<div>Unauthorized: {error.statusCode} <br /> {error.title}</div>);
                break;
            case 403:
                toastifyNotify.error(<div>Forbidden: {error.statusCode} <br /> {error.title}</div>);
                break;
            case 404:
                toastifyNotify.error(<div>Not Found: {error.statusCode} <br /> {error.title}</div>);
                break;
            case 415:
                toastifyNotify.error(<div>Code: {error.statusCode} <br /> {error.title}</div>);
                break;
            case 500:
                toastifyNotify.error(<div>Internal Server Error</div>);
                break;
            default:
                toastifyNotify.error(<div>Unknown error: {error.statusCode} <br /> {error.title}</div>);
                break;
        }
        return Promise.reject(err.response);
    }
}