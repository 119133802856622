import React, { useContext } from 'react'
import TextField from '@mui/material/TextField';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import LoadingButton from '@mui/lab/LoadingButton';
import 'react-toastify/dist/ReactToastify.css';
import Stack from '@mui/material/Stack';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Checkbox, FormControlLabel, Grid, Paper, Typography } from '@mui/material';
import openingproject from "../../images/openingproject.svg"
import { AuthContext } from '../../contexts/AuthProvider/context';
import { authenticateUser } from '../../contexts/AuthProvider/action'
import { openLoadingPage, closeLoadingPage } from '../../contexts/LoadingPageProvider/action';
import { authService } from '../../services/auth.service';
import { LoadingPageContext } from '../../contexts/LoadingPageProvider/context';

export const Login = () => {
    const { userDispatch } = useContext(AuthContext);
    const { loadingPageDispatch } = useContext(LoadingPageContext);
    const navigate = useNavigate();
    const location = useLocation();

    const validationSchema = Yup.object().shape({
        email: Yup.string().email().required(),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
            .max(20, 'Password must not exceed 20 characters'),
        acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required')
    });

    const {
        register,
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const handleOnSubmit = ((payload) => {
        openLoadingPage(loadingPageDispatch);
        async function fetchData() {
            await authService.authenticate(payload)
                .then((result) => {
                    authenticateUser(userDispatch, result);
                    navigate(location.state?.from ? location.state.from : '/');
                })
                .catch(() => {
                });
            closeLoadingPage(loadingPageDispatch);
        }
        fetchData();
    });

    return (
        <Grid container display={'flex'} justifyContent="center">
            <Paper elevation={0} sx={{ p: 4, mt: 7, ml: 2, width: '50ch' }} >

                <Typography variant="h4" component="div" gutterBottom>
                    Login
                </Typography>

                <Grid item xs={12} >
                    <TextField
                        fullWidth
                        sx={{ mb: 3 }}
                        autoFocus
                        autoComplete='false'
                        id="email"
                        label="E-mail"
                        {...register('email')}
                        error={errors.email ? true : false}
                        helperText={errors.email?.message}
                    />
                </Grid>

                <Grid item xs={12}   >
                    <TextField
                        fullWidth
                        type="password"
                        sx={{ mb: 1 }}
                        autoComplete='false'
                        id="password"
                        label="Password"
                        {...register('password')}
                        error={errors.password ? true : false}
                        helperText={errors.password?.message}
                    />
                </Grid>
                <Grid item xs={12}  >
                    <FormControlLabel
                        control={
                            <Controller
                                fullWidth
                                sx={{ mb: 1 }}
                                control={control}
                                name="acceptTerms"
                                defaultValue="false"
                                inputRef={register()}
                                render={({ field: { onChange } }) => (
                                    <Checkbox
                                        color="primary"
                                        onChange={e => onChange(e.target.checked)}
                                    />
                                )}
                            />
                        }
                        label={
                            <Typography color={errors.acceptTerms ? 'error' : 'inherit'}>
                                I have read and agree to the Terms
                            </Typography>
                        }
                    />
                </Grid>
                <Stack direction="row" sx={{ mt: 4 }}   >
                    <Grid item xs={12}>
                        <LoadingButton
                            fullWidth
                            loadingPosition="start"
                            startIcon={<ArrowCircleRightIcon />}
                            type="submit"
                            variant="outlined"
                            onClick={handleSubmit(handleOnSubmit)} >
                            Sign In
                        </LoadingButton>
                    </Grid>
                </Stack>
            </Paper>

            <Paper elevation={0} sx={{ display: 'flex', justifyContent: 'center', direction: 'column', alignItems: 'center', p: 4, mt: 7, ml: 4, width: '50ch' }} >
                <Grid item xs={12}>
                    <img width={'330vw'} alt='logo' src={openingproject} />
                </Grid>
            </Paper>
        </Grid>

    );
}