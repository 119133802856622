import { TitleHeader } from "../../../../components/Menu/TitleMenu";
import { UserTable } from "../../../../components/Table/User";

export const UserList = () => {

    return (
        <>
            <TitleHeader title='Users' />
            <UserTable />
        </>
    );
}
