import { useContext, useEffect, useState } from "react";
import { Button, FormHelperText, Grid, InputLabel, MenuItem, Select, Skeleton, TextField } from "@mui/material";
import { TitleHeader } from "../../../../components/Menu/TitleMenu";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import * as Yup from 'yup';
import { LoadingButton } from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';
import Divider from '@mui/material/Divider';
import { userService } from "../../../../services/user.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toastifyNotify } from "../../../../helpers/toast-notify";
import { validate as guidValidate } from 'uuid';
import { LoadingPageContext } from "../../../../contexts/LoadingPageProvider/context";
import { closeLoadingPage, openLoadingPage } from "../../../../contexts/LoadingPageProvider/action";
import { useFormik } from 'formik';
import { userRoleService } from "../../../../services/userRole.service";
import FormControl from '@mui/material/FormControl';
import { useCallback } from "react";

export const UserCreateAndUpdate = () => {

    const { userId } = useParams();
    const [user, setUser] = useState([]);
    const [isUserLoaded, setIsUserLoaded] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { loadingPageDispatch, loadingPageState } = useContext(LoadingPageContext);
    const [allRole, setAllRole] = useState([]);

    const validationSchema = Yup.object().shape({
        email: Yup
            .string()
            .email()
            .required()
            .max(100, 'Email must not exceed 100 characters'),
        firstName: Yup
            .string()
            .required('First name is required')
            .max(50, 'First name must not exceed 50 characters'),
        lastName: Yup
            .string()
            .required('Last name is required')
            .max(50, 'Last name must not exceed 50 characters'),
        roleId: Yup
            .string('Role is required')
            .required('Role is required'),
        password: Yup
            .string()
            .transform(x => x === '' ? undefined : x)
            .concat(!isEditMode ? Yup.string().required('Password is required') : null)
            .min(6, 'Password must be at least 6 characters')
            .max(20, 'Password must not exceed 20 characters'),
        confirmPassword: Yup
            .string()
            .transform(x => x === '' ? undefined : x)
            .concat(!isEditMode ? Yup.string().required('Confirm password is required') : null)
            .oneOf([Yup.ref('password')], 'Passwords must match'),
    });

    const formik = useFormik({
        initialValues: {
            email: user.email ? user.email : '',
            firstName: user.firstName ? user.firstName : '',
            lastName: user.lastName ? user.lastName : '',
            roleId: user.userRole?.id ? user.userRole.id : '',
            password: user.password ? user.password : '',
            confirmPassword: user.confirmPassword ? user.confirmPassword : '',
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleOnSubmit(values);
        },
    });

    const handleBackToOriginPage = useCallback(() => {
        if (location.state?.from)
            navigate(location.state.from);
        else
            navigate('/')
    }, [location, navigate]);

    //Check if a creating or editing of the user
    useEffect(() => {
        setIsEditMode(guidValidate(userId));
    }, [userId])

    //Check if a editing then get user
    useEffect(() => {
        if (isEditMode && !isUserLoaded) {
            openLoadingPage(loadingPageDispatch);
            async function fetchData() {
                setIsSubmitting(true);
                await userService.getById(userId)
                    .then(response => {
                        setIsUserLoaded(true);
                        setUser(response);
                    })
                    .catch(() => {
                        setTimeout(() => {
                            handleBackToOriginPage();
                        }, 2000);
                    });
                setIsSubmitting(false);
                closeLoadingPage(loadingPageDispatch);
            }
            fetchData();
        }
    }, [userId, isEditMode, isUserLoaded, loadingPageDispatch, handleBackToOriginPage])


    //Get all roles in the first run
    useEffect(() => {
        async function fetchData() {
            await userRoleService.getAll()
                .then(response => {
                    setAllRole(response);
                })
                .catch(() => { });
        }
        fetchData();
    }, [])

    const CreateUser = (user) => {
        const submitBody = {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            password: user.password,
            userRoleID: user.roleId
        }

        async function fetchData() {
            setIsSubmitting(true);
            await userService.create(submitBody)
                .then(() => {
                    toastifyNotify.success('User was created successfully');
                    handleBackToOriginPage();
                })
                .catch((error) => {
                    console.log('Error on while creating user', error);
                });

            setIsSubmitting(false);
        }
        fetchData();
    }

    const UpdateUser = (user) => {
        const payload = [{
            id: userId,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            userRoleID: user.roleId
        }];

        async function fetchData() {
            setIsSubmitting(true);
            await userService.update(payload)
                .then(() => {
                    toastifyNotify.success('User was update successfully');

                    if (location.state?.from)
                        navigate(location.state.from);
                    else
                        navigate('/users');
                })
                .catch((error) => {
                    console.log('Error on while updating user', error);
                });

            setIsSubmitting(false);
        }
        fetchData();
    }

    const handleOnSubmit = (values) => {
        if (isEditMode)
            UpdateUser(values);
        else
            CreateUser(values);
    }

    return (
        <>
            <TitleHeader title={isEditMode ? 'Edit user' : 'Create user'} />
            {!loadingPageState.open ? (
                <>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={1} display={'flex'} justifyContent={'center'}>
                            <Grid container spacing={1} item sm={12} md={6}  >
                                <Grid item md={12}>
                                    <TextField
                                        id="email"
                                        label="E-mail"
                                        fullWidth
                                        value={formik.values.email}
                                        disabled={isEditMode}
                                        sx={{ mb: 1 }}
                                        autoFocus
                                        autoComplete='false'
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email} />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        id="firstName"
                                        label="First name"
                                        value={formik.values.firstName}
                                        fullWidth
                                        sx={{ mb: 1 }}
                                        autoComplete='false'
                                        onChange={formik.handleChange}
                                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                        helperText={formik.touched.firstName && formik.errors.firstName}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="lastName"
                                        label="lastName"
                                        value={formik.values.lastName}
                                        fullWidth
                                        sx={{ mb: 1 }}
                                        autoComplete='false'
                                        onChange={formik.handleChange}
                                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                        helperText={formik.touched.lastName && formik.errors.lastName}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <FormControl
                                        fullWidth
                                        error={formik.touched.roleId && Boolean(formik.errors.roleId)}>
                                        <InputLabel id="role-label-id">Role</InputLabel>
                                        <Select
                                            id="roleid"
                                            labelId="role-label-id"
                                            value={formik.values.roleId}
                                            onChange={(event) => formik.setFieldValue('roleId', event.target.value)}
                                            label="role">
                                            {allRole.map((role) =>
                                                <MenuItem
                                                    key={role.id}
                                                    value={role.id}>
                                                    {role.description}
                                                </MenuItem>
                                            )}
                                        </Select>
                                        <FormHelperText>
                                            {formik.touched.roleId && formik.errors.roleId}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="password"
                                        label="Password"
                                        value={formik.values.password}
                                        fullWidth
                                        type="password"
                                        autoComplete='false'
                                        sx={{ mb: 1, display: isEditMode ? 'none' : '' }}
                                        onChange={formik.handleChange}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        helperText={formik.touched.password && formik.errors.password} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="confirmPassword"
                                        label="Confirm Password"
                                        value={formik.values.confirmPassword}
                                        fullWidth
                                        type="password"
                                        autoComplete='false'
                                        sx={{ mb: 1, display: isEditMode ? 'none' : '' }}
                                        onChange={formik.handleChange}
                                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword} />
                                </Grid>

                                <Grid item xs={12} sx={{ bgColor: "red", mb: 3 }} >
                                    <Divider />
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid container item md={12} sx={{ display: 'flex', justifyContent: 'center' }} >
                            <Grid container spacing={1} item md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Grid item md={2} >
                                    <Button
                                        color="primary"
                                        fullWidth
                                        disabled={!location.state?.from}
                                        startIcon={<ArrowCircleLeftIcon />}
                                        onClick={() => handleBackToOriginPage()}>
                                        Back
                                    </Button>
                                </Grid>
                                <Grid item md={2} >
                                    <LoadingButton
                                        color="success"
                                        fullWidth
                                        loading={isSubmitting}
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        type="submit"
                                        variant="contained"
                                    >
                                        Save
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </>
            ) : (
                <>
                    <Grid container spacing={1} display={'flex'} justifyContent={'center'}>
                        <Grid container spacing={1} item sm={12} md={6}  >
                            <Grid item xs={12} >
                                <Skeleton variant="rectangular" sx={{ mb: 1, height: 45 }} />
                                <Skeleton variant="rectangular" sx={{ mb: 1, height: 45 }} />
                            </Grid>
                            <Grid item xs={6}  >
                                <Skeleton variant="rectangular" sx={{ mb: 1, height: 45 }} />
                            </Grid>
                            <Grid item xs={6}  >
                                <Skeleton variant="rectangular" sx={{ mb: 1, height: 45 }} />
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 3 }}>
                                <Divider />
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid container spacing={1} display={'flex'} justifyContent={'flex-end'}>
                        <Grid container spacing={1} item sm={12} md={6}  >
                            <Grid item xs={3}  >
                                <Skeleton variant="rectangular" sx={{ mt: 2, height: 45 }} />
                            </Grid>
                            <Grid item xs={3}  >
                                <Skeleton variant="rectangular" sx={{ mt: 2, height: 45 }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}

        </>

    )
}