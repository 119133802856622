import { toast } from 'react-toastify';

function success(msg) {
    toast.success(msg, {
        hideProgressBar: true
    });
}

function info(msg) {
    toast.info(msg);
}

function warning(msg) {
    toast.warning(msg);
}

function error(msg) {
    toast.error(msg);
}

export const toastifyNotify = {
    success,
    info,
    warning,
    error

}

