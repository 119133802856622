import axios from 'axios';
import { handleResponse } from "../helpers/handleResponse";
import { buildHeaders } from "../helpers/handleHeader";

const baseURL = process.env.REACT_APP_API_DEVOPS;
const timeout = process.env.REACT_APP_API_DEVOPS_TIMEOUT;

export const authService = {

    authenticate: async (body) => {
        let headers = await buildHeaders.authHeaderJson();
        return await axios.post(`${baseURL}auth/authenticate`, body, { timeout: timeout, headers: headers })
            .then(result => {
                return handleResponse.success(result);
            })
            .catch(error => {
                return handleResponse.error(error);
            })
    },

};
