import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { red } from '@mui/material/colors';
import { grey } from '@mui/material/colors';



export const DialogConfirm = (props) => {

    const headerBackGroundColor = red[400];
    const headerTitleColor = red[50];
    const bodyTitleGroundColor = grey[900];
    const footerBackGroundColor = grey[100];
    const footerTitleColor = grey[400];

    let { open, title, message, nameBtnCancel, nameBtnConfirm, setOpenDialogConfirm, setDialogConfirmed } = props;

    const handleConfirmDialog = () => {
        setDialogConfirmed(true);
        setOpenDialogConfirm(false);
    }

    const handleCancelDialog = () => {
        setDialogConfirmed(false);
        setOpenDialogConfirm(false);
    }

    return (
        <Dialog
            open={open}
            onClose={() => (handleCancelDialog())}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title"
                spacing={1}
                sx={{
                    bgcolor: headerBackGroundColor
                    , color: headerTitleColor
                    , display: 'flex'
                    , justifyContent: 'center'
                }}>
                {title}
            </DialogTitle>
            <DialogContent
                sx={{
                    m: 5
                    , width: 500
                    , p: 0
                    , display: 'flex'
                    , justifyContent: 'center'
                    , alignContent: 'center'
                }}>
                <DialogContentText id="alert-dialog-description"
                    sx={{
                        color: bodyTitleGroundColor
                        , m: 0
                    }}>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{
                bgcolor: footerBackGroundColor
                , color: footerTitleColor
            }}>
                <Button sx={{ color: footerTitleColor }} onClick={() => (handleCancelDialog())} autoFocus>{nameBtnCancel}</Button>
                <Button sx={{ color: 'red' }} onClick={() => (handleConfirmDialog())}>{nameBtnConfirm}</Button>
            </DialogActions>
        </Dialog>
    );
}
