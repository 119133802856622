import { Button, Grid, Typography } from "@mui/material"
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { TitleHeader } from "../../components/Menu/TitleMenu"
import openingproject from "../../images/404.jpg"
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

export const PageNotFound = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleBackToOriginPage = () => {
        if (location.state?.from)
            Navigate(location.state.from);
        else
            navigate('/')
    }

    return (
        <>
            <TitleHeader title='404' />
            <Grid container display={'flex'} justifyContent={'center'}>
                <Grid container item sm={12} md={6} direction={'row'}>

                    <Grid item xs={6} display={'flex'} justifyContent={'center'}>
                        <img width={'400vw'} height={'400vh'} alt='logo' src={openingproject} />
                    </Grid>

                    <Grid item xs={6} display={'flex'} justifyContent={'center'} alignItems={'center'} direction={'column'} >

                        <Grid item md={12}>
                            <Typography  display="inline" variant="h4" gutterBottom>
                                It was your fault
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <Button 
                                color="primary"
                                variant="outlined"
                                startIcon={<ArrowCircleLeftIcon />}
                                fullWidth
                                onClick={() => handleBackToOriginPage()}>
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}