import { useReducer } from "react";
import { reducer } from "./reducer";
import { LoadingPageContext } from "./context";
import { data } from './data'
import P from 'prop-types';

export const LoadingPageProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, data);

    return (
        <LoadingPageContext.Provider value={{ loadingPageState: state, loadingPageDispatch: dispatch }}>
            {children}
        </LoadingPageContext.Provider>
    )
};

LoadingPageContext.prototype = {
    children: P.node.isRequired
}