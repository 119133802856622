import * as types from './types';

export const reducer = (state, action) => {

    switch (action.type) {
        case types.SET_OPEN:
            return { ...state, open: true }
        case types.SET_CLOSE:
            return { open: false }
        default:
            return { ...state }
    }
}