import { isExpired } from "react-jwt";

function getCredentials() {
    if (localStorage.getItem(process.env.REACT_APP_STORE_CREDENTIALS)) {
        return JSON.parse(localStorage.getItem(process.env.REACT_APP_STORE_CREDENTIALS));
    }
};

function saveCredentials(credentials) {
    localStorage.setItem(process.env.REACT_APP_STORE_CREDENTIALS, JSON.stringify(credentials));
}

function removeCredentials() {

    if (localStorage.getItem(process.env.REACT_APP_STORE_CREDENTIALS)) {
        localStorage.removeItem(process.env.REACT_APP_STORE_CREDENTIALS)
    }
}

function isAuthenticate() {

    if (localStorage.getItem(process.env.REACT_APP_STORE_CREDENTIALS)) {
        var credentials = JSON.parse(localStorage.getItem(process.env.REACT_APP_STORE_CREDENTIALS));

        if (!isExpired(credentials.token))
            return true;
    }

    return false;
}

export const handleLocalStorageCredentials = {
    getCredentials,
    saveCredentials,
    removeCredentials,
    isAuthenticate
};